import {createApi} from "@reduxjs/toolkit/dist/query/react"
import {apiReviewQuery} from "utils/api-config"
import {ResponseOrdersStatus} from "types/ResponseOrdersStatus"

export const ordersStatusApi = createApi({
    reducerPath: "ordersStatus",
    baseQuery: apiReviewQuery,
    tagTypes: ["orders-status"],
    endpoints: builder => ({
        // Получение
        getOrdersStatus: builder.query<ResponseOrdersStatus, void>({
            query: () =>({
              url: `orders/status`,
              method: "GET"
          }),
            providesTags: ["orders-status"]
        })
    })
})

export const {useGetOrdersStatusQuery} = ordersStatusApi
