import {createApi} from "@reduxjs/toolkit/dist/query/react"
import {apiReviewQuery} from "utils/api-config"
import {ResponseEmployee, ResponseEmployeeSelf} from "types/ResponseEmployee"


export const employeeApi = createApi({
    reducerPath: "employeeApi",
    baseQuery: apiReviewQuery,
    tagTypes: ["employee"],
    endpoints: build => ({
        getEmployees: build.query<ResponseEmployee, void>({
            query: () => ({
                url: "/employees",
                method: "GET"
            }),
            providesTags: ["employee"]
        }),
        getSelf: build.query<ResponseEmployeeSelf, void>({
            query: () => ({
                url: "/employees/get-self",
                method: "GET"
            }),
            providesTags: ["employee"]
        }),
        updateSipNumber: build.mutation<null, Partial<{id: number, sip_number: number | null}>>({
            query: ({id, sip_number}) => ({
                url: `/employees/${id}`,
                method: "PATCH",
                body: {
                    sip_number: sip_number
                }
            }),
            invalidatesTags: ["employee"]
        }),
  
    })
})

export const {useGetEmployeesQuery, useGetSelfQuery, useUpdateSipNumberMutation} = employeeApi
