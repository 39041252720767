import {createApi} from "@reduxjs/toolkit/dist/query/react"
import {apiReviewQuery} from "utils/api-config"
import {ResponseSourceTypes} from "../../../types/ResponseSourceType"

export const orderTypeApi = createApi({
    reducerPath: "orderTypeApi",
    baseQuery: apiReviewQuery,
    endpoints: builder => ({
        getOrderTypes: builder.query<ResponseSourceTypes, Partial<void>>({
            query: () => "orders/types"
        })
    })
})

export const {useGetOrderTypesQuery} = orderTypeApi
