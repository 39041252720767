import React from "react"
import {createUseStyles, useTheme} from "react-jss"
import {GlobalToken} from "antd/es/theme/interface"

const useStyles = createUseStyles<string, unknown, GlobalToken>({
    appSelectorBtn: {
        display: "grid",
        gridTemplateColumns: "3px 3px 3px",
        gridTemplateRows: "3px 3px 3px",
        gap: "6px",
        padding: "5px",
        cursor: "pointer",
        "& span": {
            display: "block",
            width: "100%",
            height: "100%",
            background: "#000",
            borderRadius: "50%"
        }
    }
})

const AppSelector = () => {
    const theme = useTheme<GlobalToken>()
    const classes = useStyles({theme})

    return <>
        <div className={classes.appSelectorBtn}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
    </>
}

export default AppSelector