import React, {useEffect, useRef, useState} from "react"
import {createUseStyles} from "react-jss"
import {GlobalToken} from "antd/es/theme/interface"
import messageImage from "assets/images/messages.svg"
import {Message} from "../../types/ResponseDiscussion"
import {useDispatch} from "../../store"
import {updateFeedbackNumber} from "../../features/feedback/mentions/mentionsSlice"
import {dateFormatter} from "../../utils/date-formatter"

interface MentionsBlockProps {
    mentions?: Message[]
}

const useStyles = createUseStyles<string, { active: boolean, isClicked: boolean }, GlobalToken>((theme) => ({
    "@keyframes shockwaveJump": {
        "0%": {
            transform: "scale(1)",
            boxShadow: "0 0 0 rgba(0, 0, 0, 0)",
            background: "rgba(0, 111, 76, .5)",
            color: "white"
        },
        "40%": {
            transform: "scale(1.08)",
            boxShadow: "0 0 25px rgba(2, 249, 73, .17)",
            background: "rgba(0, 111, 76, .8)",
            color: "white"
        },
        "50%": {
            transform: "scale(0.98)",
            boxShadow: "0 0 0 rgba(0, 0, 0, 0)",
            background: "rgba(0, 111, 76, .5)",
            color: "white"
        },
        "55%": {
            transform: "scale(1.02)",
            boxShadow: "0 0 15px rgba(2, 249, 73, .17)",
            background: "rgba(0, 111, 76, .8)",
            color: "white"
        },
        "60%": {
            transform: "scale(0.98)",
            boxShadow: "0 0 0 rgba(0, 0, 0, 0)",
            background: "rgba(0, 111, 76, .5)",
            color: "white"
        },
        "100%": {
            transform: "scale(1)",
            boxShadow: "0 0 0 rgba(0, 0, 0, 0)",
            background: "rgba(0, 111, 76, .5)",
            color: "white"
        }
    },
    "@keyframes fadeIn": {
        "0%": {
            background: "white",
            color: "black",
            boxShadow: "0 0 0 rgba(0, 0, 0, 0)"
        },
        "50%": {
            background: theme.colorPrimary,
            color: "white",
            boxShadow: "0 0 25px rgba(2, 249, 73, .17)"
        },
        "100%": {
            background: "white",
            color: "black",
            boxShadow: "0 0 0 rgba(0, 0, 0, 0)"
        }
    },
    mainWrapper: {
        position: "relative",
        "& p": {
            margin: 0
        }
    },
    mentionsCountBlock: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: "10px",
        padding: "8px",
        borderRadius: "100px",
        transition: "all 0.3s ease-in-out",
        cursor: "pointer",
        animation: ({isClicked}) => !isClicked ? "$shockwaveJump 1.5s ease-out infinite" : "none",
        border: ({active}) => `1px solid ${active ? theme.colorPrimary : "rgba(181, 181, 181, 1)"}`,
        "& p": {
            margin: 0
        }
    },
    mentionsCount: {
        width: "25px",
        height: "25px",
        borderRadius: "50%",
        backgroundColor: theme.colorPrimary,
        color: "#FFF",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    mentionList: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        padding: "5px",
        position: "absolute",
        top: ({active}) => active ? "calc(100% + 10px)" : "90%",
        left: 0,
        width: "370px",
        maxHeight: "415px",
        overflowY: "auto",
        backgroundColor: "#FFF",
        borderRadius: "10px",
        boxShadow: "0px 0px 15px 0px rgba(108, 108, 108, 0.10)",
        zIndex: 1000,
        cursor: "pointer",
        opacity: ({active}) => active ? 1 : 0,
        visibility: ({active}) => active ? "visible" : "hidden",
        transition: "all 0.3s ease-in-out",
        "& a": {
            width: "100%"
        }
    },
    mentionItem: {
        display: "grid",
        gridTemplateColumns: "30px calc(100% - 43px)",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: "10px",
        padding: "15px",
        width: "100%",
        "&:hover": {
            "& $feedbackNumber": {
                opacity: 1,
                height: "19px"
            },
            // "& $date": {
            //     opacity: 0,
            // },
            "& $name": {
                height: "0",
                opacity: 0,
            },
            "& $comment": {
                // Show 2 lines of text
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "normal",
                display: "-webkit-box",
                height: "calc(3rem + 7.2px)",
            }
        }
    },
    commentInfoBlock: {
        position: "relative"
    },
    date: {
        position: "absolute",
        top: "0",
        right: "-7px",
        width: "max-content",
        color: "#797979"
    },
    userNameBlock: {
        width: "30px",
        height: "30px",
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: theme.colorPrimary,
        color: "#FFF",
        fontSize: "13px",
        fontWeight: 500
    },
    comment: {
        color: "#B5B5B5",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        width: "100%",
        margin: 0,
        transition: "all 0.2s ease-in-out",
        height: "calc(1rem + 2.4px)",
    },
    name: {
        maxWidth: "225px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        margin: 0,
        height: "19px",
        transition: "all 0.2s ease-in-out",
        "& p.hide": {
            display: "none"
        }
    },
    feedbackNumber: {
        height: "0",
        opacity: 0,
        transition: "all 0.2s ease-in-out",
    }
}))

const MentionsBlock: React.FC<MentionsBlockProps> = ({
                                                         mentions
                                                     }) => {
    const [active, setActive] = useState(false)
    const [isClicked, setIsClicked] = useState(true)
    const classes = useStyles({active, isClicked})
    const ref = useRef<HTMLDivElement>(null)
    const dispatch = useDispatch()

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setActive(false)
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [ref])

    useEffect(() => {
        if (mentions) {
            if (mentions.length > 0) {
                setIsClicked(false)
            } else {
                setIsClicked(true)
            }
        }
    }, [mentions])

    const onMentionsBlockClick = () => {
        setActive(!active)
        setIsClicked(true)
    }

    return <>
        <div ref={ref} className={classes.mainWrapper}>
            <div onClick={onMentionsBlockClick} className={classes.mentionsCountBlock}>
                <img width={20} src={messageImage} alt=""/>
                <p>Сообщения</p>
                <div className={classes.mentionsCount}>{mentions ? mentions.length : 0}</div>
            </div>
            <div className={classes.mentionList}>
                {mentions && mentions.length > 0 ? mentions.map((mention) => <a
                    target={"_blank"} rel={"noreferrer"}
                    key={mention.id}
                    href={`/feedback/${mention.feedback_number}`}
                    onClick={() => {
                        dispatch(updateFeedbackNumber(Number(window.location.pathname.split("/")[2])))
                    }}
                >
                    <div className={classes.mentionItem}>
                        {mention.employee.picture_url ?
                            <img className={classes.userNameBlock} src={mention.employee.picture_url} alt=""/> :
                            <div className={classes.userNameBlock}>{
                                mention.employee.name.split("")[0].toUpperCase()
                            }</div>}
                        <div className={classes.commentInfoBlock}>
                            <p className={classes.name}>{mention.employee.name}</p>
                            <p className={classes.feedbackNumber}>Номер отзыва - {mention.feedback_number}</p>
                            <p className={classes.comment}>{mention.comment}</p>
                            <p className={classes.date}>{dateFormatter(mention.created_date)}</p>
                        </div>
                    </div>
                </a>) : <p style={{padding: "15px"}}>Нет cообщений</p>}
            </div>
        </div>
    </>
}

export default MentionsBlock