import {useEffect, useState} from "react"
import {updateToken, useGetAuthToken} from "../features/auth/authSlice"
import {useAuth0} from "@auth0/auth0-react"
import {useDispatch} from "../store"

export const useGetAndFetchToken = () => {
    const token = useGetAuthToken()
    const {getAccessTokenSilently, user} = useAuth0()
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        // Токен авторизации
        getAccessTokenSilently({detailedResponse: true}).then(r => {
            setLoading(true)
            dispatch(updateToken(r.access_token))
            setLoading(false)
        })
    }, [dispatch, getAccessTokenSilently])

    return {loading, token, user}
}
