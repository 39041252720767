import {createApi} from "@reduxjs/toolkit/dist/query/react"
import {apiReviewQuery} from "utils/api-config"
import {ResponseRatingCategoryTypes} from "types/ResponseRatingCategoryTypes"

export const ratingCategoryTypeApi = createApi({
    reducerPath: "ratingCategoryTypeApi",
    baseQuery: apiReviewQuery,
    endpoints: builder => ({
        getRatingCategoryTypes: builder.query<ResponseRatingCategoryTypes, Partial<void>>({
            query: () => "rating-category-types"
        })
    })
})

export const {useGetRatingCategoryTypesQuery} = ratingCategoryTypeApi
