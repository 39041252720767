import {createApi} from "@reduxjs/toolkit/dist/query/react"
import {apiReviewQuery} from "../../../utils/api-config"
import {ResponseDiscussion} from "../../../types/ResponseDiscussion"

export const discussionApi = createApi({
    reducerPath: "discussionApi",
    tagTypes: ["Discussion"],
    baseQuery: apiReviewQuery,
    endpoints: (builder) => ({
        getMessages: builder.query<ResponseDiscussion, {feedback_id: string}>({
            query: (data) => ({
                url: `discussions?feedback_id=${data.feedback_id}`,
                method: "GET"
            }),
            providesTags: ["Discussion"]
        }),
        sendMessage: builder.mutation<null, {
            feedback_id: string,
            comment: string,
            parent_id?: string,
            mention_ids?: string[]
            attachment_ids?: string[]
        }>({
            query: (data) => ({
                url: `/discussions`,
                method: "POST",
                body: data
            }),
            invalidatesTags: ["Discussion"]
        })
    })
})

export const {
    useSendMessageMutation,
    useGetMessagesQuery
} = discussionApi