import {createApi} from "@reduxjs/toolkit/dist/query/react"
import {apiReviewQuery} from "../../utils/api-config"

interface ResponseDashboard {
    data: {
        url: string
        token: string
    }
}

interface ArgsProps {
    branch_id?: string
}

export const dashboardApi = createApi({
    reducerPath: "dashboardApi",
    baseQuery: apiReviewQuery,
    tagTypes: ["dashboard"],
    endpoints: build => ({
        getDashboardByBranchId: build.query<ResponseDashboard, ArgsProps>({
            query: data => ({
                url: `${process.env.REACT_APP_API_REVIEW}/branches/${data.branch_id}/dashboard`,
                method: "GET"
            })
        }),
        getDashboardByBranches: build.query<ResponseDashboard, void>({
            query: () => ({
                url: `${process.env.REACT_APP_API_REVIEW}/branches/dashboard`,
                method: "GET"
            })
        })
    })
})

export const {useGetDashboardByBranchIdQuery, useGetDashboardByBranchesQuery} = dashboardApi
