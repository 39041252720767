import {createApi} from "@reduxjs/toolkit/query/react"
import {apiReviewQuery} from "utils/api-config"
import {ResponseOrders} from "types/ResponseOrder"
import {OrderGlobal} from "types/OrderGlobal"

export const orderPhoneApi = createApi({
  reducerPath: "orderPhoneApi",
  baseQuery: apiReviewQuery,
  tagTypes: ["order"],
  endpoints: build => ({
    // Получить заказы по номеру клиента
    getOrdersByPhone: build.mutation<ResponseOrders, Partial<string>>({
      query: data => ({
        url: `orders/${data}`,
        method: "GET"
      }),
      invalidatesTags: ["order"]
    }),
    getOrderByGlobalId: build.query<OrderGlobal, Partial<string | undefined>>({
      query: id => `orders/${id}/global-details`,
      providesTags: ["order"]
    }),
    getOrdersByFeedbackId: build.query<ResponseOrders, string>({
      query: id => ({
        url: `orders/${id}/feedback`,
        method: "GET"
      }),
      providesTags: (_, __, id) => [{type: "order", id}]
    })
  })
})

export const {useGetOrdersByPhoneMutation, useLazyGetOrderByGlobalIdQuery, useLazyGetOrdersByFeedbackIdQuery} =
  orderPhoneApi
