import React from "react"
import {useGetPermissionsQuery} from "./permissionsApi"
import ErrorBadGateway from "components/ErrorBadGateway"
import LoadingBlock from "../../../components/LoadingBlock"

interface PermissionProviderProps {
    children?: React.ReactNode
}

const PermissionProvider: React.FC<PermissionProviderProps> = ({children}) => {
    const {isLoading, isError} = useGetPermissionsQuery()

    if (isLoading) return <LoadingBlock />
    if (isError) return <ErrorBadGateway />

    return (
        <>
            {children}
        </>
    )
}

export default PermissionProvider
