import {useEffect} from "react"
import {Client} from "@stomp/stompjs"
import {useGetAndFetchToken} from "./useGetAndFetchToken"
import {useDispatch} from "../store"
import {addFeedback} from "../features/feedback-table/feedbackTableSlice"
import { updateOrder } from "features/order-table/orderTableSlice"

export const useWebsocket = () => {
    const {token, user, loading} = useGetAndFetchToken()
    const dispatch = useDispatch()
    const client = new Client({
        brokerURL: process.env.REACT_APP_WEBSOCKET_REVIEW,
        connectHeaders: {
            Authorization: `Bearer ${token}`
        },
        reconnectDelay: 5000,
        heartbeatIncoming: 4000,
        heartbeatOutgoing: 4000
    })

    useEffect(() => {
        if (client) {
            client.onConnect = function() {
                // Если почта найдена
                if (user?.email) {
                    // Подписаться на получение новых feedbacks
                    client.subscribe(`/feedbacks/${user.email}`, function(msg) {
                        if (msg.body) {
                            const data = JSON.parse(msg.body)
                            // Добавить отзыв
                            dispatch(addFeedback(data))
                        }
                    })
                    client.subscribe(`/orders/${user.email}`, function(msg) {
                        if (msg.body) {
                            const data = JSON.parse(msg.body)
                            // Add or update order
                            dispatch(updateOrder(data))
                        }
                    })
                }
            }
            // Если подключение нет то подключиться
            if (!client.active) client.activate()
            // Отключение
            return () => {
                client.deactivate().then()
            }
        }
    }, [client, user])

    return {client, loading, user, token}
}
