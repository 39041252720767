import React from "react"
import { withAuthenticationRequired } from "@auth0/auth0-react"
import Loader from "./components/Loader"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import Layout from "./layouts/Layout"
import {useWebsocket} from "./hooks/useWebsocket"
import FeedbackProcessingTimeProvider from "./features/configurations/feedback-processing-time/FeedbackProcessingTimeProvider"
import PermissionProvider from "./features/configurations/permissions/PermissionProvider"
import LoadingBlock from "./components/LoadingBlock"

// const FeedbackTable = React.lazy(() => import("./features/feedback-table/FeedbackTable"))
const FeedbackMore = React.lazy(() => import("./features/feedback/FeedbackMore"))
const OrderMore = React.lazy(() => import("./features/orderInfo/OrderMore"))
const ErrorNotFound = React.lazy(() => import("./components/ErrorNotFound"))
const EditorRatingCategoryTable = React.lazy(
    () => import("./features/settings/rating-category/EditorRatingCategoryTable")
)
const EditorResolutionCategoryTable = React.lazy(
    () => import("./features/settings/resolution-category/EditorResolutionCategoryTable")
)
const EditorSourceTable = React.lazy(() => import("./features/source/EditorSourceTable"))
const Kpi = React.lazy(() => import("./features/kpi/Kpi"))
const SourceStatistic = React.lazy(() => import("./features/source-statistic/SourceStatistic"))
const RatingStatistic = React.lazy(() => import("./features/rating-statistic/RatingStatistic"))
const CategoryStatistic = React.lazy(() => import("./features/category-statistic/CategoryStatistic"))
const SipNumber = React.lazy(() => import("./features/sip-number/SipNumberTable"))
const Dashboard = React.lazy(() => import("./features/dashboard/Dashboard"))
const CustomerCallInfo = React.lazy(() => import("./features/customer/CustomerCallInfo"))
const Main = React.lazy(() => import("features/Main"))

function App() {
    const { loading } = useWebsocket()

    // Загрузка...
    if (loading) return <Loader />

    return (
        <Router>
            <Layout>
                <PermissionProvider>
                    <FeedbackProcessingTimeProvider>
                        <React.Suspense fallback={<LoadingBlock />}>
                            <Routes>
                                <Route path={"/dashboard"} element={<Dashboard />} />
                                <Route path={"/category-statistic"} element={<CategoryStatistic />} />
                                <Route path={"/rating-category-statistic"} element={<RatingStatistic />} />
                                <Route path={"/source-statistic"} element={<SourceStatistic />} />
                                <Route path={"/kpi"} element={<Kpi />} />
                                <Route index element={<Main />} />
                                <Route path={"/feedback/:id"} element={<FeedbackMore />} />
                                <Route path={"/rating-categories"} element={<EditorRatingCategoryTable />} />
                                <Route path={"/solution-categories"} element={<EditorResolutionCategoryTable />} />
                                <Route path={"/sources"} element={<EditorSourceTable />} />
                                <Route path={"/users"} element={<SipNumber />} />
                                <Route path={"/order/:id"} element={<OrderMore />} />
                                <Route path={"/customers/:phone/call"} element={<CustomerCallInfo />} />
                                <Route path="*" element={<ErrorNotFound />} />
                            </Routes>
                        </React.Suspense>
                    </FeedbackProcessingTimeProvider>
                </PermissionProvider>
            </Layout>
        </Router>
    )
}

export default withAuthenticationRequired(App, {
    onRedirecting: () => <Loader />
})
