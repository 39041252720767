import React from "react"
import NewSwitchOnlineStatus from "components/NewSwitchOnlineStatus"
import LanguageSelector from "components/LanguageSelector"
import AppSelector from "components/AppSelector"
import {Avatar, Dropdown, Modal} from "antd"
import {PoweroffOutlined, UserOutlined} from "@ant-design/icons"
import {createUseStyles} from "react-jss"
import {useAuth0} from "@auth0/auth0-react"
import {useGetPermissionsQuery} from "../../features/configurations/permissions/permissionsApi"
import MentionsWrapper from "../../features/feedback/mentions/MentionsWrapper"


const useStyles = createUseStyles({
    firstNavBlock: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-end",
        gap: "15px"
    }
})

interface HeaderRightBlockProps {
    extra?: React.ReactNode
}

const HeaderRightBlock: React.FC<HeaderRightBlockProps> = ({extra}) => {
    const classes = useStyles()
    const {user, logout} = useAuth0()
    const {data: permissions} = useGetPermissionsQuery()

    // Выйти из системы
    const onLogoutHandler = () => {
        Modal.confirm({
            title: "Вы действительно хотите выйти?",
            onOk: () => logout({returnTo: window.origin})
        })
    }

    const items = [
        {
            label: "Выйти",
            key: "logout",
            icon: <PoweroffOutlined />,
            onClick: onLogoutHandler
        }
    ]

    return (
        <div className={classes.firstNavBlock}>
            {extra}
            <MentionsWrapper />
            {permissions?.data?.can_write_employee_session_status && <NewSwitchOnlineStatus />}
            <LanguageSelector />
            <AppSelector />
            <Dropdown menu={{items}} arrow>
                <div>
                    <Avatar
                        icon={<UserOutlined />}
                        src={user?.picture}
                        style={{verticalAlign: "middle", background: "#016f4d"}}
                    />
                </div>
            </Dropdown>
        </div>
    )
}

export default HeaderRightBlock
