import {createAsyncThunk} from "@reduxjs/toolkit"
import {AppThunkProps} from "store"
import {ResponseSourceStatistic} from "../../types/ResponseSourceStatistic"

interface ArgsProps {
    branch_id?: string
    date_time_from?: string
    date_time_to?: string
}

export const fetchSourceStatistic = createAsyncThunk<ResponseSourceStatistic, ArgsProps, AppThunkProps>(
    "sourceStatistic/fetch",
    async (data, {signal, getState}) => {
        let params = ""
        if (data.branch_id) params += `&branch_id=${data.branch_id}`
        if (data.date_time_from) params += `&date_time_from=${data.date_time_from}`
        if (data.date_time_to) params += `&date_time_to=${data.date_time_to}`
        // Получения данных авторизации
        const {auth} = getState()
        // Запрос
        const response = await fetch(`${process.env.REACT_APP_API_REVIEW}/statistics/feedback-by-source?${params}`, {
            method: "get",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${auth.token}`
            },
            signal
        })
        return await response.json()
    }
)
