import React, {useEffect} from "react"
import {Layout as AntdLayout} from "antd"
import {createUseStyles} from "react-jss"
import {useLocation} from "react-router-dom"
import Header, {ListProps} from "./header/Header"
import {useGetPermissionsQuery} from "../features/configurations/permissions/permissionsApi"

const {Content} = AntdLayout

const useStyles = createUseStyles({
    layout: {
        background: "#ffffff"
    },
    content: {
        margin: "0",
        padding: 24,
        minHeight: 280
    },
    "@media (min-width: 1200px)": {
        content: {
            margin: "0",
            padding: 24,
            minHeight: 280
        }
    }
})

interface LayoutProps {
    children?: React.ReactNode
}

const Layout: React.FC<LayoutProps> = ({children}) => {
    const classes = useStyles()
    const location = useLocation()
    const {data} = useGetPermissionsQuery()

    const list: ListProps[] = [
        {
            title: "Главная",
            link: "/"
        },
        {
            title: "Категории сервиса и продукта",
            link: "/rating-categories",
            hidden: data?.data?.can_write_feedback_rating_category
        },
        {
            title: "Категории решений",
            link: "/solution-categories",
            hidden: data?.data?.can_write_feedback_resolution_category
        },
        {
            title: "Канал отзыва",
            link: "/sources",
            hidden: data?.data?.can_write_feedback_source
        },
        {
            title: "Пользователи",
            link: "/users",
            hidden: data?.data?.can_read_statistics_kpi_operator
        },
        {
            title: "KPI операторов",
            link: "/kpi",
            hidden: data?.data?.can_read_statistics_kpi_operator
        },
        {
            title: "Просмотр отзывов по каналам",
            link: "/source-statistic",
            hidden: data?.data?.can_read_statistics_feedback_source
        },
        {
            title: "Просмотр отзывов по рейтингу",
            link: "/rating-category-statistic",
            hidden: data?.data?.can_read_statistics_feedback_rating
        },
        {
            title: "Просмотр отзывов по категориям",
            link: "/category-statistic",
            hidden: data?.data?.can_read_statistics_feedback_rating_by_category
        },
        {
            title: "Показатели филиала",
            link: "/dashboard",
            hidden: data?.data?.can_read_all_branch_dashboards || data?.data?.can_read_branch_dashboard
        }
    ]

    useEffect(() => {
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
    }, [location])

    return (
        <AntdLayout className={classes.layout}>
            <Header title={"Система отзывов"} list={list} />
            <Content className={classes.content}>
                {children}
            </Content>
        </AntdLayout>
    )
}

export default Layout
