import React, { useEffect, useRef, useState } from "react"
import { createUseStyles, useTheme } from "react-jss"
import { GlobalToken } from "antd/es/theme/interface"
import { DownOutlined } from "@ant-design/icons"

interface DropdownBlockProps {
    selected: React.ReactNode
    list: React.ReactNode[]
    onSelect: () => void
    disabled?: boolean
}

const useStyles = createUseStyles<string, { isDrop: boolean, disabled: boolean }, GlobalToken>(theme => ({
    wrapper: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "5px",
    },
    currentBlock: {
        display: "flex",
        alignItems: "center",
        gap: ".5rem",
        background: "#fff",
        border: `1px solid ${theme.colorBorder}`,
        padding: "10px 25px",
        borderRadius: "100px",
        position: "relative",
        cursor: ({ disabled }) => disabled ? "not-allowed" : "pointer",
        opacity: ({ disabled }) => disabled ? .5 : 1,
        "& p": {
            margin: 0,
        }
    },
    dropDownMenu: {
        position: "absolute",
        top: ({ isDrop }) => isDrop ? "calc(100% + 10px)" : "90%",
        opacity: ({ isDrop }) => isDrop ? 1 : 0,
        visibility: ({ isDrop }) => isDrop ? "visible" : "hidden",
        left: 0,
        right: 0,
        display: "flex",
        alignItems: "center",
        gap: ".5rem",
        background: "#fff",
        border: ({ disabled }) => disabled ? `1px solid ${theme.colorBorder}` : `1px solid ${theme.colorBorder}`,
        padding: "10px 25px",
        borderRadius: "100px",
        zIndex: 1,
        transition: "all .15s ease-in-out",
        cursor: "pointer",
        "& > div": {
            display: "flex",
            alignItems: "center",
            gap: ".5rem",
            cursor: "pointer"
        }
    }
}))

const DropdownBlock: React.FC<DropdownBlockProps> = ({ selected, list, onSelect, disabled = false }) => {
    const ref = useRef<HTMLDivElement>(null)
    const theme = useTheme<GlobalToken>()
    const [isDrop, setIsDrop] = useState(false)
    const classes = useStyles({ theme, isDrop, disabled })

    useEffect(() => {
        if (disabled) {
            setIsDrop(false)
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setIsDrop(false)
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, disabled]);

    const handleClick = () => {
        if (!disabled) {
            setIsDrop(!isDrop)
        }
    }

    return <>
        <div ref={ref} onClick={handleClick} className={classes.currentBlock}>
            <div className={classes.wrapper}>
                {selected}
            </div>
            <DownOutlined />
            <div className={classes.dropDownMenu}>
                {list.map((item, index) =>
                    <div onClick={onSelect} key={index}>{item}</div>
                )}
            </div>
        </div>
    </>
}

export default DropdownBlock
