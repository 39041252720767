import {createApi} from "@reduxjs/toolkit/dist/query/react"
import {apiReviewQuery} from "utils/api-config"
import {
    DataCreateResolutionCategory,
    ResponseResolutionCategories
} from "types/ResponseResolutionCategories"

export const resolutionCategoryApi = createApi({
    reducerPath: "resolutionCategoryApi",
    tagTypes: ["resolution-category"],
    baseQuery: apiReviewQuery,
    endpoints: builder => ({
        getResolutionCategories: builder.query<ResponseResolutionCategories, Partial<void>>({
            query: () => "resolution-categories",
            providesTags: ["resolution-category"]
        }),
        createResolutionCategory: builder.mutation<null, Partial<DataCreateResolutionCategory>>({
            query: data => ({
                url: "resolution-categories",
                method: "POST",
                body: data
            }),
            invalidatesTags: ["resolution-category"]
        }),
        updateResolutionCategory: builder.mutation<null, Partial<{id: number, data: DataCreateResolutionCategory}>>({
            query: ({id, data}) => ({
                url: `resolution-categories/${id}`,
                method: "PATCH",
                body: data
            }),
            invalidatesTags: ["resolution-category"]
        }),
        removeResolutionCategory: builder.mutation<object, Partial<number>>({
            query: id => ({
                url: `resolution-categories/${id}`,
                method: "DELETE"
            }),
            invalidatesTags: ["resolution-category"]
        })
    })
})

export const {
    useGetResolutionCategoriesQuery,
    useCreateResolutionCategoryMutation,
    useUpdateResolutionCategoryMutation,
    useRemoveResolutionCategoryMutation
} = resolutionCategoryApi
