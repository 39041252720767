import {createApi} from "@reduxjs/toolkit/dist/query/react"
import {apiReviewQuery} from "utils/api-config"
import {ResponseFeedbacks} from "types/ResponseFeedbacks"
import {ArgsProps} from "../feedback-table/fetchFeedbacks"

export const categoryFeedbackApi = createApi({
    reducerPath: "categoryFeedbackApi",
    baseQuery: apiReviewQuery,
    tagTypes: ["category-feedback"],
    endpoints: build => ({
        getCategoryFeedbacks: build.query<ResponseFeedbacks, ArgsProps>({
            query: data => ({
                url: `${process.env.REACT_APP_API_REVIEW}/feedbacks-search`,
                method: "POST",
                body: {...data}
            })
        })
    })
})

export const {useGetCategoryFeedbacksQuery} = categoryFeedbackApi
