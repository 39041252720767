import {createEntityAdapter, createSlice, PayloadAction} from "@reduxjs/toolkit"
import {ResponseDiscussion} from "../../../types/ResponseDiscussion"
import {StoreState} from "../../../store"
import {useSelector} from "react-redux"
import {fetchDiscussion} from "./fetchDiscussion"

export const discussionAdapter = createEntityAdapter<ResponseDiscussion["data"][0]>()

export interface StateProps {
    loading: boolean;
}

const initialState = discussionAdapter.getInitialState<StateProps>({
    loading: false
})

const discussionSlice = createSlice({
    name: "discussion",
    initialState,
    reducers: {
        addMessage: (state, action: PayloadAction<ResponseDiscussion["data"][0]>) => {
            discussionAdapter.addOne(state, action.payload)
        },     // Добавить отзыв
        updateMessage: (state, action: PayloadAction<ResponseDiscussion["data"][0]>) => {
            discussionAdapter.upsertOne(state, action.payload)
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchDiscussion.pending, state => {
            state.loading = true
        })
        builder.addCase(fetchDiscussion.fulfilled, (state, action) => {
            if (action.payload && action.payload.success) {
                // Изменить список отзывов
                discussionAdapter.setAll(state, action.payload.data)
            } else {
                // Удалить все отзывы
                discussionAdapter.removeAll(state)
            }
            state.loading = false
        })
        builder.addCase(fetchDiscussion.rejected, state => {
            state.loading = false
        })
    }
})

export default discussionSlice.reducer
// Действия
export const {
    addMessage,
    updateMessage
} = discussionSlice.actions
// Методы для хуков
const {selectAll} = discussionAdapter.getSelectors<StoreState>(state => state.discussion)
// Вывод всех отзывов
export const useGetDiscussions = () => useSelector(selectAll)