import {fetchKpi} from "./fetchKpi"
import {createSlice} from "@reduxjs/toolkit"
import {ResponseKpi} from "../../types/ResponseKpi"
import {useSelector} from "react-redux"
import {StoreState} from "../../store"

interface StateProps {
    loading: boolean
    total_kpi: ResponseKpi["data"][0]["total_kpi"] | []
    operator_kpi: ResponseKpi["data"][0]["operator_kpi"] | []
}

const initialState: StateProps = {
    loading: false,
    total_kpi: [],
    operator_kpi: []
}

const kpiSlice = createSlice({
    name: "kpi",
    initialState: initialState,
    reducers: {},
    extraReducers: builder => {
        // Получить KPI
        builder.addCase(fetchKpi.pending, state => {
            state.loading = true
        })
        builder.addCase(fetchKpi.fulfilled, (state, action) => {
            if (action.payload && action.payload.success && action.payload.data.length > 0) {
                state.operator_kpi = action.payload.data[0]["operator_kpi"]
                state.total_kpi = action.payload.data[0]["total_kpi"]
                state.loading = false
            } else {
                state.operator_kpi = []
                state.total_kpi = []
                state.loading = false
            }
        })
        builder.addCase(fetchKpi.rejected, state => {
            state.loading = false
        })
    }
})

export default kpiSlice.reducer

export const useGetKpi = () => useSelector((state: StoreState) => state.kpi)
export const useGetKpiLoading = () => useSelector<StoreState, boolean>(state => state.kpi.loading)
