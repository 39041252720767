import moment from "moment"

export const dateFormatter = (date: string) => {
    if (moment(date).tz("UTC").isSame(moment().tz("UTC"), "day")) {
        return moment(date).tz("UTC").format("HH:mm")
    }
    if (moment(date).tz("UTC").isSame(moment().tz("UTC"), "year")) {
        return moment(date).tz("UTC").format("DD.MM HH:mm")
    }
    return moment(date).tz("UTC").format("DD.MM.YYYY HH:mm")
}