import React from "react"
import {LoadingOutlined} from "@ant-design/icons"
import {createUseStyles, useTheme} from "react-jss"
import {GlobalToken} from "antd/es/theme/interface"

const useStyles = createUseStyles<string, unknown, GlobalToken>(theme => ({
    container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        fontSize: theme.fontSize,
        height: "100vh"
    },
    text: {
        marginTop: "1rem",
        fontSize: theme.fontSize
    }
}))

const Loader = () => {
    const theme = useTheme<GlobalToken>()
    const classes = useStyles({theme})

    return (
        <div className={classes.container}>
            <LoadingOutlined />
            <div className={classes.text}>Загрузка...</div>
        </div>
    )
}

export default Loader
