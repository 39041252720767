import {createApi} from "@reduxjs/toolkit/query/react"
import {apiReviewQuery} from "utils/api-config"
import {DataCreateSource, ResponseSources} from "types/ResponseSources"

export const sourceApi = createApi({
    reducerPath: "sourceApi",
    baseQuery: apiReviewQuery,
    tagTypes: ["source"],
    endpoints: build => ({
        // Получить статусы
        getSources: build.query<ResponseSources, Partial<void>>({
            query: data => ({
                url: `sources`,
                method: "GET",
                body: data
            }),
            providesTags: ["source"]
        }),
        createSource: build.mutation<null, Partial<DataCreateSource>>({
            query: data => ({
                url: "sources",
                method: "POST",
                body: data
            }),
            invalidatesTags: ["source"]
        }),
        updateSources: build.mutation<null, Partial<{id: number, data: DataCreateSource}>>({
            query: ({id, data}) => ({
                url: `sources/${id}`,
                method: "PATCH",
                body: data
            }),
            invalidatesTags: ["source"]
        }),
        removeSources: build.mutation<void, Partial<number>>({
            query: id => ({
                url: `sources/${id}`,
                method: "DELETE"
            }),
            invalidatesTags: ["source"]
        })
    })
})

export const {useGetSourcesQuery, useCreateSourceMutation, useUpdateSourcesMutation, useRemoveSourcesMutation} = sourceApi
