import React from "react"
import {useGetFeedbackProcessingSpeedTimeQuery, useGetFeedbackProcessingTimeQuery} from "./feedbackProcessingTimeApi"
import ErrorBadGateway from "components/ErrorBadGateway"
import LoadingBlock from "../../../components/LoadingBlock"

interface FeedbackProcessingTimeProviderProps {
    children: React.ReactNode;
}

const FeedbackProcessingTimeProvider: React.FC<FeedbackProcessingTimeProviderProps> = ({children}) => {
    const {isLoading, isError} = useGetFeedbackProcessingTimeQuery()
    const {isLoading: speedIsLoading, isError: speedIsError} = useGetFeedbackProcessingSpeedTimeQuery()

    if (isLoading || speedIsLoading) return <LoadingBlock />
    if (isError || speedIsError) return <ErrorBadGateway />

    return (
        <>
            {children}
        </>
    )
}

export default FeedbackProcessingTimeProvider
