import {createApi} from "@reduxjs/toolkit/dist/query/react"
import {employeeApi} from "features/employee/employeeApi"
import {apiReviewQuery} from "utils/api-config"

export const employeeSessionsApi = createApi({
    reducerPath: "employeeSessionsApi",
    baseQuery: apiReviewQuery,
    tagTypes: ["employee-sessions"],
    endpoints: builder => ({
        // Активировать
        activateEmployeeSession: builder.mutation<null, void>({
            query: () => ({
                url: `/employee-sessions/activate`,
                method: "post"
            }),
            invalidatesTags: ["employee-sessions"],
            onCacheEntryAdded: (args, {dispatch}) => {
                dispatch(employeeApi.util.invalidateTags(["employee"]))
            }
        }),
        // Деактивировать
        deactivateEmployeeSession: builder.mutation<null, void>({
            query: () => ({
                url: `/employee-sessions/deactivate`,
                method: "post"
            }),
            invalidatesTags: ["employee-sessions"],
            onCacheEntryAdded: (args, {dispatch}) => {
                dispatch(employeeApi.util.invalidateTags(["employee"]))
            }
        }),
        // Получить текущий статус
        myStatusEmployeeSession: builder.query<{success: true, data: "ACTIVE" | "INACTIVE"}, void>({
            query: () => `/employee-sessions/my-status`,
            providesTags: ["employee-sessions"]
        })
    })
})

export const {
    useActivateEmployeeSessionMutation,
    useDeactivateEmployeeSessionMutation,
    useMyStatusEmployeeSessionQuery
} = employeeSessionsApi
