import {createApi} from "@reduxjs/toolkit/dist/query/react"
import {apiReviewQuery} from "utils/api-config"
import {ResponseFeedbackProcessingTime} from "types/ResponseFeedbackProcessingTime"

export const feedbackProcessingTimeApi = createApi({
    reducerPath: "feedbackProcessingTimeApi",
    baseQuery: apiReviewQuery,
    tagTypes: ["feedback-processing-time", "feedback-processing-speed-time"],
    endpoints: builder => ({
        getFeedbackProcessingTime: builder.query<ResponseFeedbackProcessingTime, void>({
            query: () => `configurations/feedback-processing-time`,
            providesTags: ["feedback-processing-time"]
        }),
        getFeedbackProcessingSpeedTime: builder.query<ResponseFeedbackProcessingTime, void>({
            query: () => `configurations/feedback-processing-speed-time`,
            providesTags: ["feedback-processing-speed-time"]
        })
    })
})

export const {useGetFeedbackProcessingTimeQuery, useGetFeedbackProcessingSpeedTimeQuery} = feedbackProcessingTimeApi
