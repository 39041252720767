import React from "react"
import {useWebsocketMentions} from "../../../hooks/useWebsocketMentions"
import MentionsBlock from "../../../components/mentions/MentionsBlock"
import {useGetMentions} from "./mentionsSlice"
import LoadingBlock from "../../../components/LoadingBlock"

const MentionsWrapper = () => {
    const {loading} = useWebsocketMentions()
    const mentions = useGetMentions()

    if (loading) {
        return <LoadingBlock/>
    }

    return (
        <div>
            <MentionsBlock mentions={mentions.mentions}/>
        </div>
    )
}

export default MentionsWrapper