import {createApi} from "@reduxjs/toolkit/query/react"
import {apiReviewQuery} from "utils/api-config"
import {ResponsePhoneUrl} from "../../types/ResponsePhoneUrl"
import RepsponseOrderDetail from "types/ResponseOrderDetail"
import {Audio} from "types/FeedbackMore"

export const orderMoreApi = createApi({
    reducerPath: "orderMoreApi",
    baseQuery: apiReviewQuery,
    tagTypes: ["orderMore"],
    endpoints: builder => ({
        getAudioById: builder.mutation<Audio, string>({
            query: id => `orders/call/${id}`,
            invalidatesTags: ["orderMore"]
        }),
        getOrderById: builder.query<RepsponseOrderDetail, Partial<string | undefined>>({
            query: id => `orders/${id}/details`,
            providesTags: ["orderMore"]
        }),
        getPhoneUrl: builder.query<ResponsePhoneUrl, {phone_number: string, order_id: string}>({
            query: data => ({
                url: `/orders/call`,
                method: "POST",
                body: {
                    order_id: data.order_id,
                    phone_number: data.phone_number
                }
            })
        })
    })
})

export const {useGetOrderByIdQuery, useLazyGetPhoneUrlQuery, useGetAudioByIdMutation} = orderMoreApi
