import {createApi} from "@reduxjs/toolkit/dist/query/react"
import {apiReviewQuery} from "utils/api-config"
import {ResponseFeedbacks} from "types/ResponseFeedbacks"
import {ArgsProps} from "../feedback-table/fetchFeedbacks"

export const ratingFeedbackApi = createApi({
    reducerPath: "ratingFeedbackApi",
    baseQuery: apiReviewQuery,
    tagTypes: ["rating-feedback"],
    endpoints: build => ({
        getRatingFeedbacks: build.query<ResponseFeedbacks, ArgsProps>({
            query: data => ({
                url: `${process.env.REACT_APP_API_REVIEW}/feedbacks-search`,
                method: "POST",
                body: {...data},
                cache: "no-cache"
            })
        })
    })
})

export const {useGetRatingFeedbacksQuery} = ratingFeedbackApi
