import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {Message} from "../../../types/ResponseDiscussion"
import {StoreState} from "../../../store"
import {useSelector} from "react-redux"
import {fetchMentions} from "./fetchMentions"

export interface StateProps {
    loading: boolean
    feedback_number?: number
    mentions: Message[]
}

const initialState: StateProps = {
    loading: false,
    feedback_number: undefined,
    mentions: []
}

const mentionSlice = createSlice({
    name: "mention",
    initialState,
    reducers: {
        addMentions: (state, action: PayloadAction<Message>) => {
            state.mentions = [...state.mentions, action.payload]
        },
        updateMentions: (state, action: PayloadAction<Message[]>) => {
            state.mentions = action.payload
        },
        updateFeedbackNumber: (state, action: PayloadAction<number>) => {
            state.feedback_number = action.payload
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchMentions.pending, state => {
            state.loading = true
        })
        builder.addCase(fetchMentions.fulfilled, (state, action) => {
            if (action.payload && action.payload.success) {
                state.mentions = action.payload.data
            } else {
                state.mentions = []
            }
            state.loading = false
        })
        builder.addCase(fetchMentions.rejected, state => {
            state.mentions = []
            state.loading = false
        })
    }
})

export default mentionSlice.reducer
// Действия
export const {
    addMentions,
    updateMentions,
    updateFeedbackNumber
} = mentionSlice.actions
// Методы для хуков
const selectAll = (state: StoreState) => state.mention
// Вывод всех отзывов
export const useGetMentions = () => useSelector(selectAll)