import {createApi} from "@reduxjs/toolkit/dist/query/react"
import {apiReviewQuery} from "utils/api-config"
import {IIkoCouponRequestBody, ResponseResolutionCategoryTypes} from "types/ResponseResolutionCategoryTypes"

export const resolutionCategoryTypeApi = createApi({
    reducerPath: "resolutionCategoryTypeApi",
    baseQuery: apiReviewQuery,
    endpoints: builder => ({
        getResolutionCategoryTypes: builder.query<ResponseResolutionCategoryTypes, Partial<void>>({
            query: () => "resolution-category-types"
        }),
        saveIKOCoupon: builder.mutation<void, Partial<IIkoCouponRequestBody>>({
            query: body => ({
                url: `iiko-coupons?resolution_category_id=${body.resolution_category_id}`,
                method: "POST",
                body: body.file
            })
        })
    })
})

export const {useGetResolutionCategoryTypesQuery, useSaveIKOCouponMutation} = resolutionCategoryTypeApi
