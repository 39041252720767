import {createAsyncThunk} from "@reduxjs/toolkit"
import {AppThunkProps} from "store"
import {ResponseCategoryStatistic} from "../../types/ResponseCategoryStatistic"
import { getUrl } from "utils/api-config"

interface ArgsProps {
    date_time_from?: string
    date_time_to?: string
    branch_id?: string
    rating_category_type_id?: number
}

interface DownloadArgsProps {
    date_time_from?: string
    date_time_to?: string
    branch_id?: string
    rating_category_type: string | null
    rating_category_id_list: number[]
}

export const fetchCategoryStatistic = createAsyncThunk<ResponseCategoryStatistic, ArgsProps, AppThunkProps>(
    "ratingStatistic/fetchCategoryStatistic",
    async (data, {signal, getState}) => {
        // Получения данных авторизации
        const {auth} = getState()
        // Запрос
        const response = await fetch(getUrl(`${process.env.REACT_APP_API_REVIEW}/statistics/rating-by-category`, data), {
            method: "get",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${auth.token}`
            },
            signal
        })
        if (!response.ok) {
            throw new Error(`Server error: ${response.status}`)
        }

        return await response.json()
    }
)

export const downloadCategoryStatistics = createAsyncThunk<Record<string, never>, DownloadArgsProps, AppThunkProps>(
    "statistics/rating-by-category/download",
    async (data, {signal, getState}) => {
        // Получения данных авторизации
        const {auth} = getState()

        // Запрос
        const response = await fetch(getUrl(`${process.env.REACT_APP_API_REVIEW}/statistics/rating-by-category/download`, data), {
            method: "get",
            headers: {
                Accept: "multipart/form-data",
                Authorization: `Bearer ${auth.token}`
            },
            signal
        })

        const resp = await response.blob()
        const urlCreator = window.URL || window.webkitURL;
        const url = urlCreator.createObjectURL(resp);
        const a = document.createElement("a");
            a.style.display = "none";
            a.href = url;
            // the filename you want
            a.download = "statistics-rating-by-category.xlsx";
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
       
        return await response.json()
    }
)