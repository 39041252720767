import {createApi} from "@reduxjs/toolkit/dist/query/react"
import {apiReviewQuery} from "utils/api-config"
import {ResponsePermission} from "types/ResponsePermission"

export const feedbackPermissionApi = createApi({
    reducerPath: "feedbackPermission",
    baseQuery: apiReviewQuery,
    tagTypes: ["feedback-permissions"],
    endpoints: builder => ({
        // Получение
        getPermissions: builder.query<ResponsePermission, void>({
            query: () => `configurations/permissions`,
            providesTags: ["feedback-permissions"]
        })
    })
})

export const {useGetPermissionsQuery} = feedbackPermissionApi
