import {createEntityAdapter, createSlice, PayloadAction} from "@reduxjs/toolkit"
import {fetchFeedbacks} from "./fetchFeedbacks"
import {ResponseFeedbacks} from "types/ResponseFeedbacks"
import {StoreState} from "store"
import {useSelector} from "react-redux"

export const feedbackTableAdapter = createEntityAdapter<ResponseFeedbacks["data"]["content"][0]>()

export interface StateProps {
    loading: boolean;
    total: number
}

const initialState = feedbackTableAdapter.getInitialState<StateProps>({
    loading: false,
    total: 0
})

const feedbackTableSlice = createSlice({
    name: "feedback-table",
    initialState,
    reducers: {
        // Добавить отзыв
        addFeedback: (state, action: PayloadAction<ResponseFeedbacks["data"]["content"][0]>) => {
            feedbackTableAdapter.addOne(state, action.payload)
        },     // Добавить отзыв
        updateFeedback: (state, action: PayloadAction<ResponseFeedbacks["data"]["content"][0]>) => {
            feedbackTableAdapter.upsertOne(state, action.payload)
        }
    },
    extraReducers: builder => {
        // Получить список отзывов
        builder.addCase(fetchFeedbacks.pending, state => {
            // Начать загрузку
            state.loading = true
        })
        builder.addCase(fetchFeedbacks.fulfilled, (state, action) => {
            if (action.payload && action.payload.success) {
                // Изменить список отзывов
                feedbackTableAdapter.setAll(state, action.payload.data.content)
                // Изменить общее кол-во отзывов
                state.total = action.payload.data.total_elements
            } else {
                // Удалить все отзывы
                feedbackTableAdapter.removeAll(state)
                // Общее кол-во изменить на 0
                state.total = 0
            }
            // Остановить загрузку
            state.loading = false
        })
        builder.addCase(fetchFeedbacks.rejected, state => {
            // Удалить все отзывы
            feedbackTableAdapter.removeAll(state)
            // Общее кол-во изменить на 0
            state.total = 0
            // Остановить загрузку
            state.loading = false
        })
    }
})

export default feedbackTableSlice.reducer
// Действия
export const {updateFeedback, addFeedback} = feedbackTableSlice.actions
// Методы для хуков
const {selectAll} = feedbackTableAdapter.getSelectors<StoreState>(state => state.feedbackTable)
// Вывод всех отзывов
export const useGetFeedbacks = () => useSelector(selectAll)
// Вывод общее кол-во
export const useGetFeedbackTableTotal = () => useSelector((state: StoreState) => state.feedbackTable.total)
// Вывод загрузки
export const useGetFeedbackTableLoading = () => useSelector((state: StoreState) => state.feedbackTable.loading)
