import {createEntityAdapter, createSlice} from "@reduxjs/toolkit"
import {useSelector} from "react-redux"
import {StoreState} from "../../store"
import {ResponseSourceStatistic} from "../../types/ResponseSourceStatistic"
import {fetchSourceStatistic} from "./fetchSourceStatistic"

export const kpiAdapter = createEntityAdapter<ResponseSourceStatistic["data"][0]>({
    selectId: item => item.name
})

interface StateProps {
    loading: boolean
}

const initialState = kpiAdapter.getInitialState<StateProps>({
    loading: false
})

const kpiSlice = createSlice({
    name: "sourceStatistic",
    initialState: initialState,
    reducers: {},
    extraReducers: builder => {
        // Получить KPI
        builder.addCase(fetchSourceStatistic.pending, state => {
            state.loading = true
        })
        builder.addCase(fetchSourceStatistic.fulfilled, (state, action) => {
            if (action.payload && action.payload.success) {
                kpiAdapter.setAll(state, action.payload.data)
            } else {
                kpiAdapter.removeAll(state)
            }
            // Остановить загрузку
            state.loading = false
        })
        builder.addCase(fetchSourceStatistic.rejected, state => {
            state.loading = false
        })
    }
})

export default kpiSlice.reducer

const {selectAll} = kpiAdapter.getSelectors<StoreState>(state => state.sourceStatistic)

export const useGetSourceStatistic = () => useSelector(selectAll)
export const useGetSourceStatisticLoading = () => useSelector<StoreState, boolean>(state => state.sourceStatistic.loading)