import {createApi} from "@reduxjs/toolkit/dist/query/react"
import {apiReviewQuery} from "utils/api-config"
import {ResponseSourceTypes} from "../../../types/ResponseSourceType"

export const sourceTypeApi = createApi({
    reducerPath: "sourceTypeApi",
    baseQuery: apiReviewQuery,
    endpoints: builder => ({
        getSourceTypes: builder.query<ResponseSourceTypes, Partial<void>>({
            query: () => "source-types"
        })
    })
})

export const {useGetSourceTypesQuery} = sourceTypeApi
