import React, { useEffect, useState } from "react"
import { createUseStyles, useTheme } from "react-jss"
import { GlobalToken } from "antd/es/theme/interface"
import {
    useActivateEmployeeSessionMutation,
    useDeactivateEmployeeSessionMutation,
    useMyStatusEmployeeSessionQuery
} from "../features/employee-sessions/employeeSessionsApi"
import { notification } from "antd"
import cn from "classnames"
import DropdownBlock from "./DropdownBlock"

const useStyles = createUseStyles<string, unknown, GlobalToken>({
    indicator: {
        display: "block",
        width: "15px",
        height: "15px",
        borderRadius: "50%",
        background: "rgba(226, 32, 53, 1)",
        border: "3px solid rgba(252, 201, 201)"
    },
    active: {
        background: "rgba(20, 88, 57, 1)",
        border: "3px solid rgba(191, 248, 207)"
    }
})

const NewSwitchOnlineStatus = () => {
    const theme = useTheme<GlobalToken>()
    const [isOnline, setIsOnline] = useState(false)
    const classes = useStyles({ theme })
    const { data: status, isLoading } = useMyStatusEmployeeSessionQuery()
    const [activate, { isLoading: isLoadingActivate }] = useActivateEmployeeSessionMutation()
    const [deactivate, { isLoading: isLoadingDeactivate }] = useDeactivateEmployeeSessionMutation()

    async function onChangeHandler(val: boolean) {
        if (val) {
            await activate()
            setIsOnline(true)
        } else {
            const response = await deactivate()
            if (
                "error" in response &&
                "data" in response.error &&
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                "message" in response.error.data &&
                typeof response.error.data.message === "string"
            ) {
                notification.error({
                    message: "Ошибка!",
                    description: response.error.data.message
                })
            } else {
                setIsOnline(false)
            }
        }
    }

    useEffect(() => {
        if (status && status.data === "ACTIVE") setIsOnline(status.success)
    }, [status])

    return <>
        <DropdownBlock
            disabled={isLoading && isLoadingActivate && isLoadingDeactivate}
            selected={(
                <>
                    <div className={cn(classes.indicator, { [classes.active]: isOnline })}></div>
                    <div className={classes.currentStatusText}>{isOnline ? "Онлайн" : "Оффлайн"}</div>
                </>
            )}
            list={[(
                <>
                    <div className={cn(classes.indicator, { [classes.active]: !isOnline })}></div>
                    <div className={classes.currentStatusText}>{isOnline ? "Оффлайн" : "Онлайн"}</div>
                </>
            )]}
            onSelect={() => onChangeHandler(!isOnline)}
        />
    </>
}

export default NewSwitchOnlineStatus