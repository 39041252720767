import {createApi} from "@reduxjs/toolkit/dist/query/react"
import {apiReviewQuery} from "utils/api-config"
import {ResponseRatingCategories} from "types/ResponseRatingCategories"
import {ResponseRatingCategoriesV2} from "../../../types/ResponseCategories"

export interface CreateRatingCategoriesType {
    title?: {
        uz: string,
        ru: string
    }
    type?: number,
    parent_category_ids?: number[],
    deletion?: boolean,
    ratings?: number[],
    source_types?: string[],
    order_types?: string[],
}

export const ratingCategoryApi = createApi({
    reducerPath: "ratingCategoryApi",
    tagTypes: ["rating-category"],
    baseQuery: apiReviewQuery,
    endpoints: builder => ({
        getRatingCategories: builder.query<ResponseRatingCategoriesV2, void>({
            query: () => ({
                url: "rating-categories/v2",
                method: "GET"
            }),
            providesTags: ["rating-category"]
        }),
        createRatingCategory: builder.mutation<ResponseRatingCategories, Partial<CreateRatingCategoriesType>>({
            query: data => ({
                url: "rating-categories/v2",
                method: "POST",
                body: data
            }),
            invalidatesTags: ["rating-category"]
        }),
        updateRatingCategory: builder.mutation<ResponseRatingCategories, Partial<{id: number, data: CreateRatingCategoriesType}>>({
            query: ({id, data}) => ({
                url: `rating-categories/v2/${id}`,
                method: "PATCH",
                body: data
            }),
            invalidatesTags: ["rating-category"]
        }),
        removeRatingCategory: builder.mutation<ResponseRatingCategories, {
            parent_id: number | null,
            id: number
        }>({
            query: data => ({
                url: `rating-categories/v2/${data.id}${data.parent_id ? `?parent_id=${data.parent_id}` : ""}`,
                method: "DELETE"
            }),
            invalidatesTags: ["rating-category"]
        })
    })
})

export const {
    useGetRatingCategoriesQuery,
    useCreateRatingCategoryMutation,
    useRemoveRatingCategoryMutation,
    useUpdateRatingCategoryMutation
} = ratingCategoryApi
