import {createApi} from "@reduxjs/toolkit/query/react"
import {apiReviewQuery} from "utils/api-config"
import {ResponseStatuses} from "types/ResponseStatuses"

export const statusApi = createApi({
    reducerPath: "statusApi",
    baseQuery: apiReviewQuery,
    tagTypes: ["status"],
    endpoints: build => ({
        // Получить статусы
        getStatuses: build.query<ResponseStatuses, Partial<void>>({
            query: data => ({
                url: `statuses`,
                method: "GET",
                body: data
            }),
            providesTags: ["status"]
        })
    })
})

export const {useGetStatusesQuery} = statusApi
