import React from "react"
import {createRoot} from "react-dom/client"
import App from "./App"
import {Provider} from "react-redux"
import {store} from "./store"
import {ConfigProvider} from "antd"
import {Auth0Provider} from "@auth0/auth0-react"
import "antd/dist/reset.css"
import "assets/styles/font-face.css"
import "assets/styles/globals.css"
import ThemeProvider from "./layouts/ThemeProvider"
import moment from "moment"
import "moment-timezone"
import "moment/locale/ru"
import locale from "antd/locale/ru_RU"
import {Chart as ChartJS, ArcElement, Tooltip, Legend} from "chart.js"
import "./index.css"

const rootElement = document.getElementById("root")
if (!rootElement) throw new Error("Failed to find the root element")
const root = createRoot(rootElement)

if (!process.env.REACT_APP_AUTH_DOMAIN) throw new Error("Failed to find the REACT_APP_AUTH_DOMAIN")
if (!process.env.REACT_APP_AUTH_CLIENT_ID) throw new Error("Failed to find the REACT_APP_AUTH_CLIENT_ID")

moment.tz.setDefault("Asia/Tashkent")
moment.locale("ru")
ChartJS.register(ArcElement, Tooltip, Legend)

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <ConfigProvider
                locale={locale}
                theme={{
                    token: {
                        colorPrimary: "#006f4c",
                        colorText: "#26282d",
                        colorLink: "#006f4c",
                        colorPrimaryBg: "rgba(212, 254, 224, 1)",
                        fontSize: 16,
                        fontSizeSM: 14,
                        colorBgLayout: "#e3e4e4",
                        colorBorder: "#e3e4e4",
                        red: "#ff2837",
                        orange: "#ffc428"
                    },
                    components: {
                        Layout: {
                            colorBgLayout: "#FFF",
                            colorBgContainer: "#FFF"
                        },
                        Radio: {
                            colorPrimary: "#ff2837"
                        },
                        Select: {
                            colorFillSecondary: "#f1d5ab"
                        }
                    }
                }}
            >
                <Auth0Provider
                    domain={process.env.REACT_APP_AUTH_DOMAIN}
                    clientId={process.env.REACT_APP_AUTH_CLIENT_ID}
                    redirectUri={window.location.origin}
                    audience={process.env.REACT_APP_AUTH_AUDIENCE}
                >
                    <ThemeProvider>
                        <App />
                    </ThemeProvider>
                </Auth0Provider>
            </ConfigProvider>
        </Provider>
    </React.StrictMode>
)
