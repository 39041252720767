import { createApi } from "@reduxjs/toolkit/dist/query/react"
import { apiReviewQuery } from "utils/api-config"
import { ResponseSourceTypes } from "../../../types/ResponseSourceType"

export const orderSourceTypeApi = createApi({
    reducerPath: "orderSourceTypeApi",
    baseQuery: apiReviewQuery,
    endpoints: builder => ({
        getOrderSourceTypes: builder.query<ResponseSourceTypes, Partial<void>>({
            query: () => "orders/source-types"
        })
    })
})

export const { useGetOrderSourceTypesQuery } = orderSourceTypeApi
