import {createApi} from "@reduxjs/toolkit/query/react"
import {apiReviewQuery} from "utils/api-config"
import {ResponseBranches} from "types/ResponseBranches"

export const branchApi = createApi({
    reducerPath: "branchApi",
    baseQuery: apiReviewQuery,
    tagTypes: ["branch"],
    endpoints: build => ({
        // Получить статусы
        getBranches: build.query<ResponseBranches, Partial<void>>({
            query: data => ({
                url: `branches`,
                method: "GET",
                body: data
            }),
            providesTags: ["branch"]
        }),
        // Получить статусы
        syncBranches: build.mutation<ResponseBranches, Partial<void>>({
            query: data => ({
                url: `/branches/sync`,
                method: "POST",
                body: data
            }),
            invalidatesTags: ["branch"]
        })
    })
})

export const {useGetBranchesQuery, useSyncBranchesMutation} = branchApi
