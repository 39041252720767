import {createAsyncThunk} from "@reduxjs/toolkit"
import {AppThunkProps} from "store"
import {ResponseDiscussion} from "types/ResponseDiscussion"

export const fetchDiscussion = createAsyncThunk<ResponseDiscussion, {id: string}, AppThunkProps>(
    "discussion/fetchDiscussion",
    async (params, {getState, signal}) => {
        // Получения данных авторизации
        const {auth} = getState()
        // Запрос
        const response = await fetch(`${process.env.REACT_APP_API_REVIEW}/discussions?feedback_id=${params.id}`, {
            method: "get",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${auth.token}`
            },
            signal
        })
        return await response.json()
    }
)