import { createAsyncThunk } from "@reduxjs/toolkit"
import { AppThunkProps } from "store"
import { ResponseOrders } from "types/ResponseOrders"

export interface ArgsProps {
    page: number
    size: number
    processed: boolean
    type?: string
    status?: string
    branch_ids?: string[]
    customer?: string
    courier?: string
    assigned_employee?: string
}

type ReturnedType = ResponseOrders

export const fetchOrders = createAsyncThunk<ReturnedType, ArgsProps, AppThunkProps>(
    "orders/fetch",
    async (data, { signal, getState }) => {
        // Получения данных авторизации
        const { auth } = getState()
        // Запрос
        const response = await fetch(`${process.env.REACT_APP_API_REVIEW}/orders`, {
            method: "post",
            body: JSON.stringify(data),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${auth.token}`
            },
            signal
        })
        return await response.json()
    }
)