import {createApi} from "@reduxjs/toolkit/query/react"
import {apiReviewQuery} from "utils/api-config"
import {
    ResponseRatingCategories,
    ResponseRatingCategoriesByFeedbackId,
    ResponseRatingCategoriesV2,
    ResponseRatingCategorySubTypes,
    ResponseRatingCategoryTypes,
    ResponseResolutionCategoryTypes
} from "../../types/ResponseCategories"
import {ResponseResolutionCategories} from "../../types/ResponseResolutionCategories"

export const resolutionCategoryTypes = createApi({
    reducerPath: "resolutionCategoryTypes",
    baseQuery: apiReviewQuery,
    tagTypes: ["resolutionCategoryTypes"],
    endpoints: build => ({
        getRatingCategoryTypes: build.query<ResponseRatingCategoryTypes, void>({
            query: () => ({
                url: "/rating-category-types",
                method: "GET"
            })
        }),
        getRatingCategoriesById: build.query<ResponseRatingCategories, Partial<number | undefined>>({
            query: id => ({
                url: `rating-categories?rating_category_type_id=${id}`,
                method: "GET"
            }),
            providesTags: ["resolutionCategoryTypes"]
        }),
        getRatingCategoriesV2: build.query<ResponseRatingCategoriesV2, {
            rating_category_type_id?: number,
        } | undefined>({
            query: (data) => ({
                url: `rating-categories/v2${data?.rating_category_type_id ? `?rating_category_type_id=${data?.rating_category_type_id}` : ""}`,
                method: "GET"
            })
        }),
        getRatingCategoriesByFeedbackId: build.query<ResponseRatingCategoriesByFeedbackId, Partial<string | undefined>>({
            query: id => ({
                url: `ratings?feedback_id=${id}`,
                method: "GET"
            })
        }),
        getResolutionCategoryTypes: build.query<ResponseResolutionCategoryTypes, void>({
            query: () => ({
                url: "/resolution-category-types",
                method: "GET"
            })
        }),
        getResolutionCategoriesById: build.query<ResponseResolutionCategories, Partial<number | undefined>>({
            query: id => `resolution-categories?resolution_category_type=${id}`,
        }),
        addCategory: build.mutation({
            query: (body: {
                title: {
                    ru: string,
                    uz: string
                },
                type_id: number,
                sub_type_id: number
            }) => ({
                url: "/rating-categories",
                method: "POST",
                body
            }),
            invalidatesTags: ["resolutionCategoryTypes"]
        }),
        getRatingCategorySubTypes: build.query<ResponseRatingCategorySubTypes, void>({
            query: () => "/rating-category-types"
        })
    })
})

export const {useGetRatingCategoriesByIdQuery, useGetRatingCategoryTypesQuery, useGetResolutionCategoriesByIdQuery, useGetResolutionCategoryTypesQuery, useAddCategoryMutation, useGetRatingCategoriesByFeedbackIdQuery, useGetRatingCategorySubTypesQuery} = resolutionCategoryTypes
