import {useEffect} from "react"
import {Client} from "@stomp/stompjs"
import {useGetAndFetchToken} from "./useGetAndFetchToken"
import {updateMentions, useGetMentions} from "features/feedback/mentions/mentionsSlice"
import {useDispatch} from "store"
import audioFile from "assets/notification_sound.mp3"
import {Message} from "../types/ResponseDiscussion"

export const useWebsocketMentions = () => {
    const {token, user, loading} = useGetAndFetchToken()
    const connected = !!(!loading && token && user)
    const dispatch = useDispatch()
    const mentions = useGetMentions()
    const client = new Client({
        brokerURL: process.env.REACT_APP_WEBSOCKET_REVIEW,
        connectHeaders: {
            Authorization: `Bearer ${token}`
        },
        reconnectDelay: 5000,
        heartbeatIncoming: 4000,
        heartbeatOutgoing: 4000
    })

    useEffect(() => {
        if (client) {
            client.onConnect = function () {
                if (user) {
                    client.subscribe(`/discussion-notifications/${user.email}`, function (msg) {
                        if (msg.body) {
                            const data: Message[] = JSON.parse(msg.body)
                            dispatch(updateMentions(data))
                            data.forEach(item => {
                                if (item.show_notification) {
                                    if (mentions.feedback_number === item.feedback_number) {
                                        new Audio(audioFile).play()
                                    } else {
                                        new Notification("Новое уведомление", {
                                            body: item.comment,
                                            icon: item.employee.picture_url,
                                            tag: item.id,
                                        }).onclick = () => {
                                            window.open(`/feedback/${item.feedback_number}`)
                                        }
                                        new Audio(audioFile).play()
                                    }
                                }
                            })
                        }
                    })
                }
            }

            if (!client.active) client.activate()

            return () => {
                client.deactivate().then()
            }
        }
    }, [client, user])


    return {client, loading, user, token, connected}
}
